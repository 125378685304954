import React from "react";

class AccordionItem extends React.Component {
    render() {
        const {
            props: {
                text,
                title,
                id
            }
        } = this

        return (
            <div
                {...{
                    className: `accordion-item'}`,
                    onClick: () => {
                        this.setState({opened: !opened})
                    }
                }}
                role="button"
                id={`${id}`}
                tabindex={`${id}`}
                on={`tap:${id}.toggleClass(class='accordion-item--opened')`}
            >
                <div {...{className: 'accordion-item__line'}}>
                    <h3 {...{className: 'accordion-item__title'}}>
                        {title}
                    </h3>
                    <span {...{className: 'accordion-item__icon'}}/>
                </div>
                <div {...{className: 'accordion-item__inner'}}>
                    <div {...{className: 'accordion-item__content'}}>
                        <div className={'accordion-item__paragraph'} dangerouslySetInnerHTML={{__html: text}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default AccordionItem
