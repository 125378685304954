import React from "react";
import cx from "classnames";
import Navigate from "../navigate";

function CategoryTitle(props) {
    const className = cx(
        "top-border-category relative border-t-default border-gray-400 font-bold pt-2 text-denim text-2xl break-word",
        props.className,
        props.className?.includes("mb") ? '' : 'mb-6',
        props.className?.includes("mt") ? '' : 'mt-2',
    );

    const render = () => {
        return <div className={className} data-datocms-noindex>
            {props.title}
        </div>;
    }

    const breadCrumb = () => {
        return <div className={className} data-datocms-noindex>
            <a href={props.parentLink} className={"text-base lg:text-2xl"}>
                {props.parentTitle}
            </a>
            <span className={"category-breadcrumb"}/>
            <a href={props.link} className={"text-base lg:text-2xl"}>
                {props.title}
            </a>
        </div>
    }

    const navigation = () => {
        if (props.parentTitle) {
            return breadCrumb();
        }
        return <a href={props.link}>
            {render()}
        </a>;
    }

    return <>
        {props.link
            ? navigation()
            : render()
        }
    </>;
}

export default CategoryTitle;
