import React from 'react';
import AccordionItem from "../accordion/index.amp";
import '../../css/components/_accordian.scss';

class Faq extends React.Component {
    render() {
        return (
            <div>
                <h2 className={"mb-2 font-semibold leading-snug"} style={{marginBottom: '10px'}}>{this.props.title}</h2>
                <ul {...{className: 'accordion-list'}}>
                    {this.props.data.map((data, key) => {
                        return (
                            <li {...{className: 'accordion-list__item', key}}>
                                <AccordionItem {...data} id={`faq-${key}`}/>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

export default Faq
